import { Injectable } from '@angular/core'

import { ExportOrderResultDto, FindOrderDateFilterDto, FindOrderDto, OrderDto, PageResultDto } from '../dto'
import { HttpService } from './http.service'

@Injectable()
export class OrderService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/orders', findOrderDto)
  }

  async findError(findOrderDto: FindOrderDto) {
    return await this.httpService.get<PageResultDto<OrderDto>>('/admin/orders/error', findOrderDto)
  }

  async findOne(id: string, {archiveStatus}: {archiveStatus: string}) {
    return await this.httpService.get<OrderDto>(`/admin/orders/${id}`, {archiveStatus})
  }

  async resolve(id: string) {
    return await this.httpService.post<Boolean>(`/admin/orders/${id}/resolve`)
  }

  async exportExcel(dateFilterDto: FindOrderDateFilterDto) {
    return await this.httpService.get<ExportOrderResultDto>(`/admin/orders/export`, dateFilterDto)
  }
}
